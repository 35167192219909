<template>
  <div
    class="flex justify-center items-center fixed w-full h-full bg-white top-0 left-0 z-50"
  >
    <el-form class="w-xl bg-gray-100 px-10 pt-5 pb-3 rounded">
      <el-form-item label="Логин">
        <el-input v-model="username"></el-input>
      </el-form-item>
      <el-form-item label="Пароль">
        <el-input show-password type="password" v-model="password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button :disabled="loading" @click="login">Войти</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { login, whoami } from "@/api/auth";

export default {
  name: "index",
  data: () => ({
    username: null,
    password: null,
    loading: false,
  }),
  methods: {
    async login() {
      this.loading = true;
      try {
        await login(this.username, this.password);
        this.$message.success("Успешно");
        const user = await whoami();
        this.$store.commit("setUser", user.me);
        this.$router.push({ name: "home" });
      } catch (e) {
        this.$message.error(e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
